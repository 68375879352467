import React from 'react';

class AboutPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      };
    }

    render() {
        return (
        <>
        <div className="w-full sm:px-6 lg:px-8 h-60 bg-slate-400">

        </div>

        <div className="container mt-2">
            <h1 className='text-2xl mb-16'>Events</h1>
        </div>

        </>
        );
    }
}


export default AboutPage